import { validate } from 'class-validator';
import { chain } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

function getConstraintPriority(constraint: {name: string; message: string}): number {
  switch (constraint.name) {
    case 'isNotEmpty':  return 0;
    default: return 1000;
  }
};

@Component
export default class Validatable extends Vue {
  async validate<T extends object>(object: T): Promise<Partial<Record<keyof T, string>>> {
    const errors = await validate(object);
    return chain(errors)
      .keyBy('property')
      .mapValues(error => chain(error.constraints)
        .map((value, key) => ({name: key, message: value}))
        .sortBy(getConstraintPriority)
        .first()
        .value().message
      )
      .value() as Partial<Record<keyof T, string>>;
  }

}
