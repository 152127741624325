
































import { Component, Vue } from 'vue-property-decorator';

import FormviewLayout from '@/components/utils/FormviewLayout.vue';

@Component({
  components: {
    FormviewLayout,
  },
})
export default class DeleteConfirmFormview extends Vue {
}
